/**
 * Contains all application-wide Sass variables
 **/

/**
 * Color System
 **/
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:     #007bff !default;
$blue-100: #BBDEFB !default;
$blue-200: #90CAF9 !default;
$blue-300: #64B5F6 !default;
$blue-400: #42A5F5 !default;
$blue-500: #2196F3 !default;
$blue-600: #1E88E5 !default;
$blue-700: #1976D2 !default;
$blue-800: #1565C0 !default;
$blue-900: #0D47A1 !default;
$blue-a700:#2962FF !default;

$indigo:     #6610f2 !default;
$indigo-100: #C5CAE9 !default;
$indigo-200: #9FA8DA !default;
$indigo-300: #7986CB !default;
$indigo-400: #5C6BC0 !default;
$indigo-500: #3F51B5 !default;
$indigo-600: #3949AB !default;
$indigo-700: #303F9F !default;
$indigo-800: #283593 !default;
$indigo-900: #1A237E !default;
$indigo-a700:#304FFE !default;

$purple:      #6f42c1 !default;
$purple-100:  #E1BEE7 !default;
$purple-200:  #CE93D8 !default;
$purple-300:  #BA68C8 !default;
$purple-400:  #AB47BC !default;
$purple-500:  #9C27B0 !default;
$purple-600:  #8E24AA !default;
$purple-700:  #7B1FA2 !default;
$purple-800:  #6A1B9A !default;
$purple-900:  #4A148C !default;
$purple-a700: #AA00FF !default;

$pink:      #e83e8c !default;
$pink-100:  #F8BBD0 !default;
$pink-200:  #F48FB1 !default;
$pink-300:  #F06292 !default;
$pink-400:  #EC407A !default;
$pink-500:  #E91E63 !default;
$pink-600:  #D81B60 !default;
$pink-700:  #C2185B !default;
$pink-800:  #AD1457 !default;
$pink-900:  #880E4F !default;
$pink-a700: #C51162 !default;

$red:      #dc3545 !default;
$red-100:  #FFCDD2 !default;
$red-200:  #EF9A9A !default;
$red-300:  #E57373 !default;
$red-400:  #EF5350 !default;
$red-500:  #F44336 !default;
$red-600:  #E53935 !default;
$red-700:  #D32F2F !default;
$red-800:  #C62828 !default;
$red-900:  #B71C1C !default;
$red-a700: #D50000 !default;

$orange:      #fd7e14 !default;
$orange-100:  #FFE0B2 !default;
$orange-200:  #FFCC80 !default;
$orange-300:  #FFB74D !default;
$orange-400:  #FFA726 !default;
$orange-500:  #FF9800 !default;
$orange-600:  #FB8C00 !default;
$orange-700:  #F57C00 !default;
$orange-800:  #EF6C00 !default;
$orange-900:  #E65100 !default;
$orange-a700: #FF6D00 !default;

$yellow:      #ffc107 !default;
$yellow-100:  #FFF9C4 !default;
$yellow-200:  #FFF59D !default;
$yellow-300:  #FFF176 !default;
$yellow-400:  #FFEE58 !default;
$yellow-500:  #FFEB3B !default;
$yellow-600:  #FDD835 !default;
$yellow-700:  #FBC02D !default;
$yellow-800:  #F9A825 !default;
$yellow-900:  #F57F17 !default;
$yellow-a700: #FFD600 !default;

$green:      #28a745 !default;
$green-100:  #C8E6C9 !default;
$green-200:  #A5D6A7 !default;
$green-300:  #81C784 !default;
$green-400:  #66BB6A !default;
$green-500:  #4CAF50 !default;
$green-600:  #43A047 !default;
$green-700:  #388E3C !default;
$green-800:  #2E7D32 !default;
$green-900:  #1B5E20 !default;
$green-a700: #00C853 !default;

$teal:      #20c997 !default;
$teal-100:  #B2DFDB !default;
$teal-200:  #80CBC4 !default;
$teal-300:  #4DB6AC !default;
$teal-400:  #26A69A !default;
$teal-500:  #009688 !default;
$teal-600:  #00897B !default;
$teal-700:  #00796B !default;
$teal-800:  #00695C !default;
$teal-900:  #004D40 !default;
$teal-a700: #00BFA5 !default;


$cyan:      #17a2b8 !default;
$cyan-100:  #B2EBF2 !default;
$cyan-200:  #80DEEA !default;
$cyan-300:  #4DD0E1 !default;
$cyan-400:  #26C6DA !default;
$cyan-500:  #00BCD4 !default;
$cyan-600:  #00ACC1 !default;
$cyan-700:  #0097A7 !default;
$cyan-800:  #00838F !default;
$cyan-900:  #006064 !default;
$cyan-a700: #00B8D4 !default;

/* Vitrolife Palete */
$vl-blue: #004a9d !default;
$vl-light-blue: #9fcdf0 !default;
$vl-super-light-blue: #e6f7ff !default;
$vl-green: #88b700 !default;
$vl-red: #f5222d !default;
$vl-yellow: #ffc107 !default;
$vl-white: $white;

$vl-black: #2d2d2f !default;
$vl-gray-0: #202022 !default;
$vl-gray-1: #606060 !default;
$vl-gray-2: #c9c9c9 !default;
$vl-gray-3: #ececec !default;
$vl-gray-4: #f5f5f5 !default;
$vl-xlight-blue: #d9ebf9 !default;


/* Theme colors */
$primary:       $vl-black       !default;
$secondary:     $vl-gray-0      !default;
$success:       $vl-green       !default;
$info:          $vl-light-blue  !default;
$warning:       $vl-yellow      !default;
$alert:         $vl-red         !default;
$light:         $gray-100       !default;
$dark:          $gray-800       !default;

/**
 * Options
 **/
$enable-rounded: true !default;

/**
 * Spacing
 **/
$space-xxsmall: 0.05rem;
$space-xsmall: 0.2rem;
$space-small: 0.5rem;
$space-normal: 1rem;
$space-large: 1.5rem;
$space-xlarge: 2.5rem;
$space-xxlarge: 5rem;
$space-size: (
  'xxsmall': $space-xxsmall,
  'xsmall': $space-xsmall,
  'small': $space-small,
  'normal': $space-normal,
  'large': $space-large,
  'xlarge': $space-xlarge,
  'xxlarge': $space-xxlarge,
  'auto': auto,
  'none': 0,
) !default;


/* Regular font family */
$font-family: 'Noto Sans';
$font-size-xxsmall: 0.5rem;
$font-size-xsmall: 0.7rem;
$font-size-small: 0.8rem;
$font-size-normal: 0.875rem;
$font-size-large: 1.1rem;
$font-size-xlarge: 1.5rem;
$font-size-xxlarge: 2.5rem;


/* Text color */
$text-color: $primary !default;

/* Breakpoints media */
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

/**
 * Relative URL where all assets are served from
 * @type String
 * @exmple scss When using on your own domain
 * $base-url: 'https://your-own-domain.com/assets/';
 **/
$base-url: '/' !default;

/* antd overwrite */
$primary-color: $primary;                       // primary color for all components
$link-color: #1890ff;                           // link color
$heading-color: $text-color;                    // heading text color

$lab-header-color: $primary-color;
$sidebar-bg-color: #001529;
$sidebar-border-color: $black;
$sidebar-trigger-bg-color: #002140;
$sidebar-trigger-icon-color: $white;
$sidebar-text-color: $white;

/**
 * Contains all application-wide Sass functions
 **/

/**
 * Native `url(...)` function wrapper
 * @param {String} $base - base URL for the asset
 * @param {String} $type - asset type folder (e.g `fonts/`)
 * @param {String} $path - asset path
 * @return {Url}
 **/
@function asset($base, $type, $path) {
    @return url($base + $type + $path);
}

/**
 * Returns URL to an image based on its path
 * @param {String} $path  - image path
 * @param {String} $base [$base-url] - base URL
 * @return {Url}
 * @require $base-url
 **/
@function image($path, $base: $base-url) {
    @return asset($base, 'images/', $path);
}

/**
 * Returns URL to a fond based on its path
 * @param {String} $path  - font path
 * @param {String} $base [$base-url] - base URL
 * @return {Url}
 * @require $base-url
 **/
@function font($path, $base: $base-url) {
    @return asset($base, 'fonts/', $path);
}

/**
 * Slightly lighten a color
 * @access public
 * @param {Color} $color - color to tint
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
 **/
@function tint($color, $percentage) {
  @return mix($white, $color, $percentage);
}

/**
 * Slightly darken a color
 * @access public
 * @param {Color} $color - color to shade
 * @param {Number} $percentage - percentage of `$color` in returned color
 * @return {Color}
**/
@function shade($color, $percentage) {
  @return mix($black, $color, $percentage);
}

/**
 * white trasnparency
 **/
@function white-trasnparency($percentage) {
  @return rgba(255, 255, 255, $percentage);
}

/**
 * black trasnparency
 **/
@function black-trasnparency($percentage) {
  @return rgba(0, 0, 0, $percentage);
}

/**
 * Contains all application-wide Sass mixins
 **/

/* Utitilities */
@import '../../../node_modules/helix/www/helix/styles/definitions/mixins/border';
@import '../../../node_modules/helix/www/helix/styles/definitions/mixins/hover';
@import '../../../node_modules/helix/www/helix/styles/definitions/mixins/media';
@import '../../../node_modules/helix/www/helix/styles/definitions/mixins/spacing';


$color-primary: #1ea7fd;

.gene-form {
  @include margin-vertical('normal');

  .ant-col.ant-form-item-label {
    width: 200px;
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
}
