.list-wrapper {
  th.ant-table-cell {
    text-transform: uppercase;
    font-weight: bold;
  }

  .ant-table-filter-trigger {
    font-size: 16px;
  }
}
