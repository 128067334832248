$noto-sans-font-path: '../../../../public/fonts/noto-sans/NotoSans-Regular.ttf' ;
$noto-sans-bold-font-path: '../../../../public/fonts/noto-sans/NotoSans-Bold.ttf' ;
$noto-sans-sc-font-path: '../../../../public/fonts/noto-sans-sc/NotoSansSC-Regular.otf' ;
$noto-sans-sc-bold-font-path: '../../../../public/fonts/noto-sans-sc/NotoSansSC-Bold.otf' ;

@font-face {
  font-family: 'Noto Sans';
  src: url($noto-sans-font-path) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans';
  src: url($noto-sans-bold-font-path) format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans SC';
  src: url($noto-sans-sc-font-path) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Noto Sans SC';
  src: url($noto-sans-sc-bold-font-path) format('opentype');
  font-weight: bold;
  font-style: normal;
}
