@import 'helix-styles/reset.scss';
@import 'helix-styles/definitions/constants.scss';
@import 'helix-styles/definitions/functions.scss';
@import 'helix-styles/definitions/mixins.scss';
@import 'helix-styles/definitions/palette.scss';

@import 'styles/definitions/fonts/notosans.scss';

@import 'styles/definitions/antd/form.scss';
@import 'styles/definitions/helix/list.scss';

#root,
.ant-modal-root {
  h1 {
    font-size: $font-size-large;
    font-weight: bold;
    text-transform: uppercase;
  }

  h2 {
    font-size: $font-size-large;
    font-weight: bold;
  }

  h3 {
    font-size: $font-size-normal;
    font-weight: bold;
  }

  h4 {
    font-size: $font-size-small;
    font-weight: bold;
  }
}
